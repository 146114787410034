import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';

import {
  Box, Button,
  ButtonLink,
  ErrorIcon,
  Input,
  Pill,
  TagIcon,
  Text,
  TickIcon
} from '@myob/myob-widgets';
import { get } from 'lodash';
import { useProductData } from 'hooks/useProductData/useProductData';
import { trackClickEvent } from 'hooks/GTM4Analytics/useGTMAnalytics';
import { GTM4_ANALYTICS_CONSTANTS, ANALYTICS_ID } from '../../constants';

const PROMO_CODE_STATE = {
  EMPTY: 'EMPTY',
  VALID: 'VALID',
  INVALID: 'INVALID'
};

const TICK_ICON = {
  [PROMO_CODE_STATE.VALID]: <TickIcon className='promo-code-input-tick-icon'/>,
  [PROMO_CODE_STATE.INVALID]: <ErrorIcon className='promo-code-input-error-icon'/>
};

export const PromoCodeBox = () => {
  const { productData } = useProductData();
  const productItems = get(productData, 'items');
  const promoCode = productItems[0].deal?.promoCode ?? '';
  const promotionName = productItems[0].deal?.promotionName ?? '';
  const [inputValue, updateInputValue] = useState(promoCode);

  const [promoCodeState, updatePromoCodeState] =
      useState(promoCode ? PROMO_CODE_STATE.VALID : PROMO_CODE_STATE.EMPTY);

  const handleUpdatePromoCode = () => {
    if (inputValue !== '') {
      trackClickEvent(GTM4_ANALYTICS_CONSTANTS.PROMO_CODE_APPLY_BUTTON_CLICK, {
        promo_code: inputValue
      });
    }
    if (!promoCode && (inputValue === '' || inputValue.trim() === '')) {
      updatePromoCodeState(PROMO_CODE_STATE.EMPTY);
    } else if (inputValue === promoCode) {
      updatePromoCodeState(PROMO_CODE_STATE.VALID);
    } else {
      updatePromoCodeState(PROMO_CODE_STATE.INVALID);
    }
  };

  const [showPromoBox, setShowPromoBox] = useState(false);

  if (showPromoBox) {
    return (
      <Box
        className={`promo-code-group ${isMobile ? 'isMobile' : ''}`}
        data-testid='promo-code-group'
        backgroundColor='#F0F0F0'
      >
        <Text className='promo-code-input-label' as='span' color='#74767B' fontSize='sm'>
          Apply promotional code
        </Text>
        <Box className='promo-code-input-text'>
          <Box className='promo-code-input-box-container'>
            <Input
              className={`promo-code-input-box ${promoCodeState.toLowerCase()}`}
              data-testid='promo-code-input'
              containerClassName='promo-code-input-container'
              hideLabel={true}
              name='promoCode'
              value={inputValue}
              onChange={(e: { target: { value: React.SetStateAction<string>; }; }) => updateInputValue(e.target.value)}
              suffixIcon={TICK_ICON[promoCodeState]}
            />
            <Text className={`promo-code-verification-text ${promoCodeState.toLowerCase()}`} fontSize='sm'/>
          </Box>
          <Button
            className='promo-code-apply-button'
            data-testid='promo-code-apply-button'
            onClick={handleUpdatePromoCode}
          >
            <p className='promo-code-apply-button-text'>
              Apply
            </p>
          </Button>
        </Box>
        {promoCodeState !== PROMO_CODE_STATE.EMPTY && (
        <div>
          {promotionName &&
          <Pill label={promotionName} prefixAccessory={<TagIcon color={'brand'}/>}
            className={'promo-code-tag'}
          />
          }
        </div>
        )}
      </Box>
    );
  }
  return (
    <div className='promo-code-link-container'>
      <ButtonLink className={'promo-code-link'} onClick={() => setShowPromoBox(true)}>
        <a id={ANALYTICS_ID.LINK_HAVE_PROMO_CODE}>Have a promotional code?</a>
      </ButtonLink>
    </div>
  );
};
