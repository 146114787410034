import React from 'react';
import { Box } from '@myob/myob-widgets';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import { ProductCard } from 'components/generic/productCard/ProductCard';
import soloImage from 'assets/images/solo-bg.svg';
import { Image } from 'components/image/Image';
import style from './LeftPanel.module.scss';

const Spacer: React.FC = () => (
  <Box className={style.spacer} />
);

type LeftPanelProps = {
  rightPanelFaded: boolean;
  setRightPanelFaded: (fade: boolean) => void;
};

export const LeftPanel: React.FC<LeftPanelProps> = (
  {
    rightPanelFaded,
    setRightPanelFaded
  }
) => {
  const { theme } = useSessionContext();
  return (
    <Box className={style.leftPanel}>
      <Image
        alt='background'
        src={soloImage}
        className={style.leftPanelBackgroundImage}
      />
      <Spacer/>
      <ProductCard
        theme={theme}
        isFooterOpen={rightPanelFaded}
        onFooterOpen={setRightPanelFaded}
      />
    </Box>
  );
};
