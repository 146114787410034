import React from 'react';
import {
  Box, Text, Heading
} from '@myob/myob-widgets';
import { Image } from 'components/image/Image';
import ArrowIcon from 'assets/svg/chevron_up_icon.svg';
// TODO apply based on theme
import style from './ProductHeading.solo.module.scss';

type ProductHeadingProps = {
  heading: string;
  handleClick?: () => void;
  isFooterOpen: boolean;
}

export const ProductHeading = (
  {
    heading,
    handleClick,
    isFooterOpen
  }: ProductHeadingProps
) => (
  <Box className={style.productHeading} onClick={isFooterOpen ? undefined : handleClick}>
    <Image
      className={isFooterOpen ? style.chevronOpen : style.chevron}
      src={ArrowIcon}
      alt='arrow'
      onClick={handleClick}
    />
    <Text
      as='span'
      display='block'
      tone='neutralStrong'
      fontSize='xs'
    >
      {heading}
    </Text>
    <Heading>Summary of your plan</Heading>
  </Box>
);
