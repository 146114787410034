import React, { useState } from 'react';
import { MYOBLogo, RadioButton, Spinner } from '@myob/myob-widgets';
import { Main } from 'common/main/Main';
import { useAuth0 } from 'hooks/Auth0/Auth0';
import { Redirect } from 'react-router-dom';
import { OrderSummary } from 'components/orderSummary/OrderSummary';
import { useContactCreation } from 'hooks/useContactsCreation/useContactCreation';
import { useAccountCreation } from 'hooks/useAccountsCreation/useAccountCreation';
import { TermsConditions } from 'components/terms/Terms';
import { useAddPaymentInfoEvent } from 'hooks/GTMAnalytics/useGTMAnalytics';
import { useSendEventToSegment } from 'hooks/useSendEventToSegment/useSendEventToSegment';
import { useGetEcommercePrice } from 'hooks/GTMAnalytics/useGTMAnalyticsHelper';
import { useSegmentContext } from 'contexts/segmentProvider/SegmentProvider';
import { useAddPaymentInfoEventGA4 } from 'hooks/GTM4Analytics/useGTMAnalytics';
import { NewFeatureContainer } from 'components/newFeatureContainer/NewFeatureContainer';
import ProductCard from 'components/productCard/ProductCard';
import { Main as GenericMain, PaymentForm } from 'components/generic';
import {
  useFeatureBoxClassName,
  useOldMobileDesign
} from 'hooks/useNewMobileDesignHooks/useNewMobileDesignHooks';
import { useHistory } from 'react-router';
import Cookies from 'js-cookie';
import { usePaymentProfileCreation } from 'hooks/usePaymentProfileCreation/usePaymentProfileCreation';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import './PaymentPage.scss';
import { DirectDebitForm } from './directDebitForm/directDebitForm';
import { CreditCardForm } from './credtiCardForm/creditCardForm';
import { CreditCardDetail, DirectDebitDetail } from './types';
import {
  COOKIE_AJS_ANONYMOUS_ID,
  COOKIE_AJS_USER_ID,
  COOKIE_MYOB_VISITOR_ID,
  FEELIX_THEMES,
  GTM_ANALYTICS_CONSTANTS,
  PAYMENT_METHOD,
  PROMOTION_CODE,
  STEP_PATH,
  STEPPER_TYPE
} from '../../constants';
import Steppers from '../stepper/Steppers';
import '../../app/App.scss';

export const PaymentPage: React.FC = () => {
  const {
    region, promoCode, promotionEndDate, pCustomerId
  } = useSessionContext();

  const { isLoading: isAuth0Loading, isAuthenticated } = useAuth0();

  const { isLoading: isContactLoading, isError: isContactError, contactDetails } = useContactCreation();

  const { isLoading: isAccountLoading, isError: isAccountError, accountDetails } =
      useAccountCreation(contactDetails?.id);
  const isApiRequestLoading = isContactLoading || isAccountLoading;

  const isApiRequestError = isContactError || isAccountError;

  const initialCardDetail = {
    cardType: '',
    cvv: '',
    expiryMonth: '',
    expiryYear: '',
    maskedNumber: '',
    nameOnCard: '',
    number: ''
  };

  const [creditCardDetail, setCreditCardDetail] = useState<CreditCardDetail>(initialCardDetail);

  const initialDirectDebitDetail: DirectDebitDetail = {
    accountName: '',
    authorised: false,
    bsb: '',
    accountNumber: '',
    bank: '',
    branch: '',
    account: '',
    suffix: ''
  };
  const [directDebitDetail, setDirectDebitDetail] = useState(initialDirectDebitDetail);
  const [paymentMethod, setPaymentMethod] = useState(PAYMENT_METHOD.CREDIT);

  const history = useHistory();
  const {
    hasPurchaseSubmitted,
    setPaymentMethod: setPaymentMethodInContext,
    isAnnualPrice,
    theme
  } = useSessionContext();

  const {
    isLoading: isPaymentProfileCreationLoading,
    refetch: createPaymentProfile,
    paymentProfile,
    isSuccess: isPaymentProfileCreationSuccess,
    isError: isPaymentProfileCreationError
  } = usePaymentProfileCreation({
    isAnnualPrice,
    contactId: contactDetails?.id,
    accountId: accountDetails?.id,
    paymentMethod,
    creditCardDetail,
    directDebitDetail
  });

  const isSegmentLibraryReady = useSegmentContext();
  const { currency, totalPrice } = useGetEcommercePrice();
  const featureBoxClassName = useFeatureBoxClassName();

  const myobVisitorId = Cookies.get(COOKIE_MYOB_VISITOR_ID);
  const anonymousId = Cookies.get(COOKIE_AJS_ANONYMOUS_ID);
  const userId = Cookies.get(COOKIE_AJS_USER_ID);
  const isOldMobileDesign = useOldMobileDesign();

  const trackParams = {
    event: GTM_ANALYTICS_CONSTANTS.ADD_PAYMENT_INFO_SEGMENT,
    userId,
    anonymousId,
    properties: {
      currency,
      value: totalPrice,
      coupon: promoCode,
      myobVisitorId
    }
  };
  useAddPaymentInfoEventGA4(isSegmentLibraryReady);
  useAddPaymentInfoEvent(GTM_ANALYTICS_CONSTANTS.ADD_PAYMENT_INFO_SEGMENT);
  useSendEventToSegment({
    shouldSendSegmentEvent: !!currency && isSegmentLibraryReady,
    trackParams
  });

  if (hasPurchaseSubmitted) {
    return <Redirect to={'/error'}/>;
  }

  if (isPaymentProfileCreationSuccess) {
    const confirmationPath = `/${region.toLowerCase()}` +
        `${isAnnualPrice ? STEP_PATH.SUBSCRIBE.CONFIRMATION_ANNUAL : STEP_PATH.SUBSCRIBE.CONFIRMATION}`;

    history.push(confirmationPath, {
      accountId: accountDetails?.id,
      paymentProfileId: paymentProfile?.id,
      contactId: contactDetails?.id,
      pCustomerId: promoCode === PROMOTION_CODE.CODE_WESTPACBUSINESSACC_12MTH_FREE ? pCustomerId : null
    });
  }

  const renderFeatures = (): React.ReactNode => (isOldMobileDesign ? <OrderSummary /> : <ProductCard />);

  const stepper = <Steppers activeStepNumber='2' firstType='complete' secondType='' stepperType={STEPPER_TYPE.PURCHASE}
    thirdType='incomplete' data-testid='step2'
  />;
  if (isAuth0Loading || isApiRequestLoading) {
    if (theme === FEELIX_THEMES.SOLO) {
      return (
        <GenericMain
          rightContent={<Spinner/>}
        />
      );
    }

    return (
      <Main>
        <NewFeatureContainer>
          {renderFeatures()}
        </NewFeatureContainer>
        <div className={featureBoxClassName}>
          {stepper}
          <div style={{ width: '6.25em', marginBottom: '1em' }}>
            <MYOBLogo />
          </div>
          <Spinner data-testid='spinner' size='medium' />
        </div>
      </Main>
    );
  }

  if (!isAuthenticated || isApiRequestError || isPaymentProfileCreationError) {
    return <Redirect to='/error' />;
  }

  const renderRadioButtonForOption = (paymentMethodOption: PAYMENT_METHOD) => {
    const label = paymentMethodOption === PAYMENT_METHOD.CREDIT ? 'Credit Card' : 'Direct Debit';
    const radioButtonClick = () => {
      setPaymentMethod(paymentMethodOption);
      setPaymentMethodInContext(paymentMethodOption);
    };
    return <RadioButton name='paymentMethod' checked={paymentMethod === paymentMethodOption}
      value={paymentMethodOption} label={label}
      onChange={radioButtonClick}
    />;
  };
  const renderPaymentForm = () => (paymentMethod === PAYMENT_METHOD.CREDIT ?
    <CreditCardForm
      isPaymentProfileCreationLoading={isPaymentProfileCreationLoading}
      setCreditCardDetail={setCreditCardDetail}
      createPaymentProfile={createPaymentProfile}
    /> :
    <DirectDebitForm
      isPaymentProfileCreationLoading={isPaymentProfileCreationLoading}
      setDirectDebitDetail={setDirectDebitDetail}
      createPaymentProfile={createPaymentProfile}
      promoCode={promoCode}
    />);

  const renderRadioButtons = () => (
    <div className={'horizontal-radio'}>
      {renderRadioButtonForOption(PAYMENT_METHOD.CREDIT)}
      &nbsp;&nbsp;
      {renderRadioButtonForOption(PAYMENT_METHOD.DEBIT)}
    </div>
  );

  if (theme === FEELIX_THEMES.SOLO) {
    return (
      <GenericMain
        rightContent={
          <PaymentForm>
            {renderRadioButtons()}
            {renderPaymentForm()}
          </PaymentForm>}
      />
    );
  }

  return (
    <Main>
      <NewFeatureContainer>
        {renderFeatures()}
      </NewFeatureContainer>
      <div className={featureBoxClassName}>
        {stepper}
        <div style={{ width: '6.25em', marginBottom: '1em' }}>
          <MYOBLogo/>
        </div>
        {renderRadioButtons()}
        {renderPaymentForm()}
        {promoCode && promotionEndDate && <TermsConditions className='terms'/>}
      </div>
    </Main>
  );
};
