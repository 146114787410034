import React from 'react';
import { isMobile, isIOS, isAndroid } from 'react-device-detect';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import { useLocation } from 'react-router-dom';

const locations: Record<string, any> = {
  solo: {
    ios: 'https://itunes.apple.com/au/app/id6502329894',
    android: 'https://play.google.com/store/apps/details?id=com.myob.solo.android'
  }
};
export const MobileAppStore: React.FC = () => {
  const { region } = useSessionContext();
  const location = useLocation();
  const product = (location.pathname.split('/')[2]) as string;
  const config = locations[product];
  // could be windows
  const valid = isMobile && (isIOS || isAndroid) && locations[product];
  const url = valid ? config[isIOS ? 'ios' : 'android'] : `https://myob.com/${region.toLowerCase()}/pricing`;
  window.location.replace(url);
  return null;
};
