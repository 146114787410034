import React from 'react';
import { Input, Spinner } from '@myob/myob-widgets';
import { DeepMap, FieldError } from 'react-hook-form';
import { Redirect } from 'react-router-dom';
import { keys } from 'lodash';
import {
  PROMOTION_CODE, REGULAR_EXPRESSIONS, WESTPAC, WESTPAC_AFFILIATED, WESTPAC_AFFILIATED_BSB, WESTPAC_BSB
} from '../../../constants';
import { DirectDebitDetail } from '../types';
import './directDebitForm.scss';
import { trimFocusEventInputValue } from '../../../helpers/trim/trim';

export const DirectDebitContainerAU: React.FC<{
  register:any,
  errors:DeepMap<DirectDebitDetail, FieldError>,
  validateBankCode: (bankCode: string) => Promise<boolean>,
  promoCode: string | undefined,
  isValidateBankCodeError: boolean,
  isValidateBankCodeLoading: boolean,
}> = ({
  register,
  errors,
  validateBankCode,
  promoCode,
  isValidateBankCodeError,
  isValidateBankCodeLoading
}) => {
  if (isValidateBankCodeError) {
    return <Redirect to='/error'/>;
  }

  return (
    <>
      <Input
        className={'input__field'}
        id='bsb'
        name='bsb'
        label='BSB number*'
        maxLength={6}
        reference={register({
          required: true,
          pattern: REGULAR_EXPRESSIONS.BSB,
          validate: {
            invalidBsb: validateBankCode,
            invalidWestpac: (bankCode: string) => (promoCode ?
              (!keys(WESTPAC).includes(promoCode) || keys(WESTPAC_AFFILIATED).includes(promoCode)) ||
              WESTPAC_BSB.includes(bankCode.slice(0, 2)) : true),
            invalidAffiliatedBanksOfWestpac: (bankCode: string) => (promoCode ?
              (!keys(WESTPAC_AFFILIATED).includes(promoCode)) || WESTPAC_AFFILIATED_BSB.includes(bankCode.slice(0, 2)) :
              true)
          }
        })}
        errorMessage={ errors.bsb && generateBsbNumberErrorMsg(errors.bsb.type) }
      />
      {isValidateBankCodeLoading &&
        <div id='bsb-loading-state' className='flex mt-min'>
          <Spinner data-testid='spinner1' size='small'/>
          <small className='ml-min loading-font'>Validating BSB number...</small>
        </div>
      }
      <Input
        className={'input__field'}
        id='accountNumber'
        name='accountNumber'
        label='Account Number*'
        reference={register({ required: true, pattern: REGULAR_EXPRESSIONS.ACCOUNT_NUMBER })}
        errorMessage={
          errors.accountNumber &&
                    (errors.accountNumber.type === 'required' ?
                      'Account number is required' : 'Invalid account number')}
      />
      <Input
        className={'input__field'}
        id='accountName'
        name='accountName'
        label='Account Name*'
        onBlur={trimFocusEventInputValue}
        reference={register({ required: true, pattern: REGULAR_EXPRESSIONS.ACCOUNT_NAME })}
        errorMessage={
          errors.accountName &&
                    (errors.accountName.type === 'required' ?
                      'Account name is required' : 'Please enter a valid account name')}
      />
      <br />
    </>
  );
};

const generateBsbNumberErrorMsg = (errorType: string): string => {
  switch (errorType) {
    case 'required': return 'BSB is required';
    case 'invalidWestpac': return 'Please add a valid Westpac business debit or ' +
      'credit card or business transaction account.';
    case 'invalidAffiliatedBanksOfWestpac': return 'Please add a valid St.George Bank, Bank of Melbourne or ' +
      'Bank SA business debit or credit card or business transaction account.';
    default: return 'Invalid BSB';
  }
};
