import React, { useState } from 'react';
import { Box } from '@myob/myob-widgets';
import { Background } from '../background/Background';
import { LeftPanel } from '../leftPanel/LeftPanel';
import { RightPanel } from '../rightPanel/RightPanel';
import style from './Main.module.scss';

type MainProps = {
  rightContent?: React.ReactNode;
  leftContent?: React.ReactNode;
}
export const Main: React.FC<MainProps> = ({ leftContent, rightContent }) => {
  const [rightPanelFaded, setRightPanelFaded] = useState(false);
  return (
    <Box className={style.main} data-testid='generic-main'>
      <Background>
        <LeftPanel
          rightPanelFaded={rightPanelFaded}
          setRightPanelFaded={setRightPanelFaded}
        >
          {leftContent}
        </LeftPanel>
        <RightPanel faded={rightPanelFaded}>
          {rightContent}
        </RightPanel>
      </Background>
    </Box>
  );
};
