import React, { useState } from 'react';
import {
  Box, Separator, Spinner
} from '@myob/myob-widgets';
import './ProductCard.scss';
import { get } from 'lodash';
import { Redirect } from 'react-router-dom';
import { useClickHandler } from 'hooks/useClickHandler/useClickHandler';
import { useProductData } from 'hooks/useProductData/useProductData';
import { useContentContext } from 'providers/contentProvider/ContentProvider';
import { isMobile } from 'react-device-detect';
import { TermsConditions } from 'components/terms/Terms';
import { PricingBox } from './PricingBox';
import { FeatureList } from './FeatureList';
import { ProductTitle } from './ProductTitle';

const ProductCard: React.FC = () => {
  const {
    isLoading, isModuleProductDataLoading,
    isError, isGetModuleProductDataError,
    productData, moduleProductData,
    baseFeatureList, baseAndModuleFeatureList,
    productId, showModulePrice,
    promoCode, availableTo
  } = useProductData();

  const { products: productContent } = useContentContext();
  const productItem = productContent?.find(
    (it) => it.productId === Number(productId)
  );
  const productName = get(productItem, 'fullName', '');
  const [open, setOpen] = useState(false);
  const ref = useClickHandler((clickedOutside) => {
    if (open && clickedOutside) {
      setOpen(false);
    }
  });

  if (isError || isGetModuleProductDataError) {
    return <Redirect to='/error' />;
  }

  const loading = isLoading || isModuleProductDataLoading;

  return (
    <Box
      ref={ref}
      className={{
        'mobile-product-card': isMobile,
        'left-hand-side': !isMobile,
        'is-open': open
      }}
      justifyContent='center'
      alignItems='center'
    >
      <Box
        className={{
          'product-card': !isMobile,
          'product-summary': isMobile
        }}
        data-testid='product-box'
        borderColor='borderDefault'
        borderWidth='thin'
        borderStyle='solid'
        borderRadius='xl'
        boxShadow='strong'
        padding='lg'
        {...(isMobile && {
          backgroundColor: 'backgroundDefault',
          borderColor: 'borderDefault',
          borderWidth: 'thin',
          borderStyle: 'solid',
          borderRadius: 'clear',
          borderTopLeftRadius: 'xl',
          borderTopRightRadius: 'xl',
          boxShadow: 'strong',
          paddingTop: 'xs',
          paddingLeft: 'md',
          paddingRight: 'md',
          paddingBottom: open ? '2xs' : 'clear'
        })}
      >
        { loading && <Spinner size='medium' data-testid='spinner'/> }
        { !loading &&
          <>
            <div className='product-card-header' onClick={() => isMobile && !open && setOpen(!open)}>
              <ProductTitle
                content={productName}
                header='Summary of your plan'
                {...(isMobile && {
                  content: 'Summary of your plan',
                  header: productName
                })
              }
              />
              { isMobile &&
                <button
                  className={`button-wrapper ${open ? 'down' : ''}`}
                  onClick={() => setOpen(!open)}
                />
              }
            </div>
            <div className='product-card-content'>
              <Separator className='title-separator' data-testid='title-separator'/>
              <PricingBox productData={productData} moduleProductData={moduleProductData}/>
              <FeatureList items={showModulePrice(productId) ? baseAndModuleFeatureList : baseFeatureList}/>
              { promoCode && availableTo && <TermsConditions className='terms-and-conditions'/>}
            </div>
          </>
          }
      </Box>
    </Box>
  );
};

export default ProductCard;
