import defaultStyles from './PricingCard.module.scss';
import soloStyles from './PricingCard.solo.module.scss';
import { FEELIX_THEMES } from '../../../../../constants';

export const getStyles = (theme: string) => {
  if (theme === FEELIX_THEMES.SOLO) {
    return soloStyles;
  }
  return defaultStyles;
};
