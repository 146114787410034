import React from 'react';
import { Box } from '@myob/myob-widgets';
import { Image } from 'components/image/Image';
import soloImage from 'assets/svg/solo-logo.svg';
import backgroundImage from 'assets/svg/mobile_landing_hero.svg';
import { ProgressBar } from 'components/generic/progressBar/ProgressBar';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import style from './RightPanel.module.scss';

type RightPanelProps = {
  faded: boolean
}
export const RightPanel: React.FC<RightPanelProps> = ({ children, faded }) => {
  const { flowStep } = useSessionContext();
  return (
    <Box className={[style.rightPanel, {
      [style.rightPanelFaded]: faded,
      [style.lavenderColorMobile]: flowStep === -1
    }]}
    >
      <Box
        className={style.rightPanelContent}
      >
        <Image
          alt='solo'
          src={soloImage}
          className={`${style.logo} ${flowStep === -1 && style.hideLogo}`}
        />
        <ProgressBar/>
        <Image
          alt='background'
          src={backgroundImage}
          className={`${style.rightPanelBackgroundImage} ${flowStep !== -1 && style.hideBackgroundImage}`}
        />
        {children}
      </Box>
    </Box>
  );
};
