import React from 'react';
import {
  Box,
  Button,
  Input,
  Heading,
  Text
} from '@myob/myob-widgets';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import { useContentContext } from 'providers/contentProvider/ContentProvider';
import { ELEMENTS } from 'providers/contentProvider/Elements';
import { ANALYTICS_ID, COLOURS_AS_HEX, REGULAR_EXPRESSIONS } from '../../../constants';
import soloStyle from './EmailForm.solo.module.scss';

type EmailFormProps = {
  handleSubmit: () => void;
  submitDisabled: boolean;
  formRegister: (data: any) => void;
  isError: boolean;
  errors: any;
}

export const EmailForm: React.FC<EmailFormProps> = (
  {
    handleSubmit,
    submitDisabled,
    formRegister,
    isError,
    errors
  }
) => {
  const { phoneNumber } = useSessionContext();
  const { getLabels } = useContentContext();
  return (
    <Box className={soloStyle.container} data-testid='email-form'>
      <Heading level={1} marginBottom='xs'>
        {getLabels(ELEMENTS.EMAIL_PAGE_HEADING_ONE)}
      </Heading>
      <Text fontSize='sm' fontWeight='inherit' marginBottom='md'>
        {getLabels(ELEMENTS.EMAIL_PAGE_HEADING_TWO)}
      </Text>

      <Input
        data-testid='email-input'
        reference={formRegister({ required: true, pattern: REGULAR_EXPRESSIONS.EMAIL })}
        name='email'
        label='Email'
        requiredLabel='Email is required'
        containerClassName={soloStyle.inputContainer}
        className={soloStyle.input}
        errorMessage={
          errors.email &&
          (errors.email.type === 'required' ? 'Email is required' : 'Email is invalid')}
      />
      {isError &&
        <p style={{ marginTop: '1rem', color: COLOURS_AS_HEX.Berry }}>
          Something went wrong. If the issue persists, contact MYOB support on{' '}
          {phoneNumber.salesPhoneNumber}
        </p>
      }
      <Button
        id={ANALYTICS_ID.BUTTON_EXISTING_USER_CHECK}
        tone='brand'
        className={soloStyle.submitButton}
        data-testid='email-submit'
        onClick={handleSubmit}
        disabled={submitDisabled}
      >
        {getLabels(ELEMENTS.EMAIL_SUBMIT_BUTTON)}
      </Button>
    </Box>
  );
};
