import React from 'react';
import { Box } from '@myob/myob-widgets';
import { getStyles } from './styles';
import { PricingMolecule } from './PricingMolecule';

type PriceProps = {
  price: string;
  currency: string;
  paymentSchedule: string;
  isExcludeTax: boolean;
}
type PricingCardProps = {
  pricing: PriceProps,
  theme: string
}

const getPriceDenominator = (paymentSchedule: string, isExcludeTax: boolean) => {
  const taxDescription = isExcludeTax ? '(+ GST)' : '(inc. GST)';
  return `/${paymentSchedule} ${taxDescription}`;
};

export const PricingCard: React.FC<PricingCardProps> = ({
  pricing: {
    price, paymentSchedule, isExcludeTax, currency
  }, theme
}) => (
  <Box className={getStyles(theme).pricingCard}>
    <PricingMolecule
      price={price}
      denominator={getPriceDenominator(paymentSchedule, isExcludeTax)}
      currency={currency}
    />
  </Box>
);
