import React from 'react';
import { Redirect } from 'react-router-dom';
import { useNinetailedCheckoutEvent } from 'hooks/useNinetailedAnalytics/useNinetailedAnalytics';
import ConfirmationPage from '../ConfirmationPage';
import { OrderSummary } from '../../orderSummary/OrderSummary';
import { ANALYTICS_ID, GTM_ANALYTICS_CONSTANTS, NINETAILED_ANALYTICS_CONSTANTS } from '../../../constants';
import laptopImage from '../../../assets/images/computer-screen.png';
import { useAuth0 } from '../../../hooks/Auth0/Auth0';
import { useContentViewed, useSendPurchaseEventAndOrderCompletedEvent } from '../../../hooks/Analytics/useAnalytics';
import { useEnhancedPurchaseEvent, usePurchaseEvent } from '../../../hooks/GTMAnalytics/useGTMAnalytics';
import { useSendEventToSegmentForPurchase } from '../useSendEventToSegmentForPurchase';
import NewTrialStepper from '../../stepper/newTrial/NewTrialStepper';
import '../Confirmation.scss';
import { useContactCreation } from '../../../hooks/useContactsCreation/useContactCreation';
import { useAccountCreation } from '../../../hooks/useAccountsCreation/useAccountCreation';
import { useTrialCreation } from '../../../hooks/useTrialCreation/useTrialCreation';
import { usePurchaseEventGA4 } from '../../../hooks/GTM4Analytics/useGTMAnalytics';
import ProductCard from '../../productCard/ProductCard';
import { useOldMobileDesign } from '../../../hooks/useNewMobileDesignHooks/useNewMobileDesignHooks';

function trackNewTrialConfirmationPage(email?: string) {
  /* eslint-disable react-hooks/rules-of-hooks */
  useContentViewed({
    elementId: ANALYTICS_ID.CONTENT_VIEWED_TRIAL_CONFIRMATION
  });
  useSendPurchaseEventAndOrderCompletedEvent();
  usePurchaseEventGA4();
  usePurchaseEvent(GTM_ANALYTICS_CONSTANTS.PURCHASE_SEGMENT);
  useSendEventToSegmentForPurchase();
  useEnhancedPurchaseEvent(email);
  /* eslint-enable react-hooks/rules-of-hooks */
}

const NewTrialConfirmation: React.FC = () => {
  const { user: { email } = {}, isLoading: isAuth0Loading, isAuthenticated } = useAuth0();
  const { isLoading: isContactLoading, isError: isContactError, contactDetails } = useContactCreation();
  const { isLoading: isAccountLoading, isError: isAccountError, accountDetails } =
      useAccountCreation(contactDetails?.id);
  const {
    isLoading: isTrialLoading,
    isError: isTrialError
  } = useTrialCreation(accountDetails?.id);
  const isOldMobileDesign = useOldMobileDesign();

  const isApiRequestLoading = isContactLoading || isAccountLoading || isTrialLoading;
  const isApiRequestError = isContactError || isAccountError || isTrialError;
  trackNewTrialConfirmationPage(email);
  useNinetailedCheckoutEvent(NINETAILED_ANALYTICS_CONSTANTS.PURCHASE);

  const isLoading = isAuth0Loading || isApiRequestLoading;
  if (!isLoading && (!isAuthenticated || isApiRequestError)) {
    return <Redirect to={'/error'}/>;
  }

  return <ConfirmationPage
    featureList={isOldMobileDesign ? <OrderSummary /> : <ProductCard />}
    isLoading={isLoading}
    stepper={<div className='trial-stepper'><NewTrialStepper activeStepNumber='2' /></div>}
    pageTitle='What happens next?'
    mainDescription={
      <>
        <img className='confirmation-page-main-image' src={laptopImage} alt='desktop'/>
        <div className='confirmation-page-main-image-desc'>
          <p><b>Access your product</b></p>
          <p>Learn the ropes with the help of your own personalised setup activities</p>
        </div>
      </>
    }
  />;
};

export default NewTrialConfirmation;
