import { isMobile } from 'react-device-detect';
import useFeatureToggle from '../useFeatureToggle/useFeatureToggle';
import { FEATURE_TOGGLE, SUBSCRIPTION_TYPE } from '../../constants';
import { useSessionContext } from '../../contexts/sessionProvider/SessionProvider';

export const useFeatureBoxClassName = (): string => {
  const { subscriptionType } = useSessionContext();
  const isTrial = subscriptionType === SUBSCRIPTION_TYPE.TRIAL;
  const newMobileDesignEnabled = useFeatureToggle(FEATURE_TOGGLE.NEW_MOBILE_DESIGN_TOGGLE);
  const useFullHeightContent = newMobileDesignEnabled && isMobile && !isTrial;
  return `feature-box-right${useFullHeightContent ? ' full-height' : ''}`;
};

export const useOldMobileDesign = () : boolean => {
  const newMobileDesignEnabled = useFeatureToggle(FEATURE_TOGGLE.NEW_MOBILE_DESIGN_TOGGLE);
  return isMobile && !newMobileDesignEnabled;
};
