import { Box, SuccessIcon, Text } from '@myob/myob-widgets';
import React from 'react';
import style from './FeatureList.solo.module.scss';

export const FeatureList = (props: { items: string[] }): React.JSX.Element => (
  <ul
    className={style.featureListContainer}
    data-testid='feature-list-container'
  >
    {props.items.map((feature) => (
      <li key={feature} data-testid='feature-item'>
        <Box
          alignItems='flex-start'
          display='flex'
          gap='2xs'
          color='neutralStrong'
          marginBottom='sm'
        >
          <div className={style.successIcon} data-testid='success-icon'>
            <SuccessIcon color='currentColor' size='lg'/>
          </div>
          <Text as='span' tone='neutralStrong' fontSize='sm'>
            {feature}
          </Text>
        </Box>
      </li>
    ))}
  </ul>);
