import React from 'react';
import { Button as FeelixButton } from '@myob/myob-widgets';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import { FEELIX_THEMES } from '../../constants';

export const Button: React.FC<React.ComponentProps<typeof FeelixButton>> = (
  { children, ...props }
) => {
  const { theme } = useSessionContext();
  return (
    <FeelixButton
      {...props}
      {...theme === FEELIX_THEMES.SOLO ? { tone: 'brand' } : {}}
    >
      {children}
    </FeelixButton>
  );
};
