import React from 'react';
import { Redirect } from 'react-router-dom';
import { Box, Spinner } from '@myob/myob-widgets';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import { useClickHandler } from 'hooks/useClickHandler/useClickHandler';
import { useProductData } from 'hooks/useProductData/useProductData';
import { ProductData } from 'hooks/useGetProduct/types';
import { ProductHeading } from './components/ProductHeading';
import { PricingCard } from './components/PricingCard';
import defaultStyle from './ProductCard.module.scss';
import soloStyle from './ProductCard.solo.module.scss';
import { FEELIX_THEMES } from '../../../constants';
import { getDiscountPrice } from '../../../utils/pricingUtils';
import isNzPPLProduct from '../../../utils/IsNzPPLProduct';
import { FeatureList } from './components/FeatureList';
import { TermsConditions } from './components/Terms';

type ProductCardProps = {
  theme: string;
  isFooterOpen: boolean;
  onFooterOpen: (open: boolean) => void;
}

const getPricing = (productData: ProductData, isAnnualPrice: boolean) =>
  ({
    price: getDiscountPrice(productData?.items[0], isAnnualPrice, productData?.region),
    currency: productData.currency || 'AUD',
    isExcludeTax: isNzPPLProduct(productData?.items[0]?.productId),
    // when annual is moved to nbs this will be dynamic
    paymentSchedule: isAnnualPrice ? 'year' : 'month'
  });

export const ProductCard: React.FC<ProductCardProps> = (
  {
    theme,
    isFooterOpen,
    onFooterOpen
  }
) => {
  const { isAnnualPrice } = useSessionContext();
  const {
    productData,
    isError,
    isLoading,
    baseFeatureList,
    baseAndModuleFeatureList,
    productId,
    showModulePrice,
    promoCode,
    availableTo
  } = useProductData();

  const ref = useClickHandler((clickedOutside) => {
    if (isFooterOpen && clickedOutside) {
      onFooterOpen(false);
    }
  });

  if (isLoading) return <Spinner size='large'/>;
  if (isError) return <Redirect to='/error' />;
  return (
    <Box
      ref={ref}
      className={
      [defaultStyle.productCard, {
        [soloStyle.productCard]: theme === FEELIX_THEMES.SOLO
      }]
    }
    >
      <ProductHeading
        heading={productData?.items[0].displayName}
        handleClick={() => onFooterOpen(!isFooterOpen)}
        isFooterOpen={isFooterOpen}
      />
      <Box className={[defaultStyle.productFooter, {
        [defaultStyle.productFooterOpen]: isFooterOpen
      }]}
      >
        <PricingCard
          pricing={getPricing(productData, isAnnualPrice)}
          theme={theme}
        />
        <FeatureList
          items={showModulePrice(productId) ? baseAndModuleFeatureList : baseFeatureList}
        />
        { promoCode && availableTo && <TermsConditions className={defaultStyle.termsAndConditions}/>}
      </Box>
    </Box>
  );
};
